import React from "react";

export default function Team({ bgColor }) {
  return (
    <>
      <section id="team" className={`team-member-section ptb-100 ${bgColor}`}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-3 col-sm-6">
              <div className="single-team-member position-relative">
                <div className="team-image">
                  <img
                    src="assets/img/affiliate/priscila-tonetto.jpeg"
                    alt="Team Members"
                    className="img-fluid rounded-circle"
                  />
                </div>
                <div className="team-info text-white rounded-circle d-flex flex-column align-items-center justify-content-center">
                  <h5 className="mb-0">Priscila Tonetto</h5>
                  <h6 className="text-center mt-4">Especialista em BPO<br/>para imobiliárias</h6>
                  <ul className="list-inline team-social social-icon mt-4 text-white">
                    <li className="list-inline-item">
                      <a href="https://www.instagram.com/priscilatonetto/" target="_blank" rel="noreferrer">
                        <span className="ti-instagram"><small className="pl-1">priscilatonetto</small></span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-8">
              <div className="section-heading text-center mb-5">
                <span className="text-uppercase color-secondary sub-title">
                  Condição especial facilitada por
                </span>
                <h2>Priscila Tonetto</h2>
                <p className="lead">
                  Quem é Priscila Tonetto? Muito prazer, meu nome é Priscila Tonetto, sou Diretora de Operações na OPUL BPO Financeiro.
                  Com mais de 20 anos de experiência na área Financeira, formada em Gestão Financeira e MBA em Controladoria e Finanças, já ajudei diversos negócios a organizarem suas finanças, aumentando os lucros, reduzindo custos e alcançando seus objetivos financeiros. Somos especialistas no mercado imobiliário atuando na gestão operacional financeira, como, contas a pagar, contas a receber e fluxo de caixa, trazendo assim previsibilidade e agilidade, permitindo que os empresários tenham mais tempo para se dedicar ao seu negócio.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

