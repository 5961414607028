import React from "react";

export default function Team({ bgColor }) {
  return (
    <>
      <section id="team" className={`team-member-section ptb-100 ${bgColor}`}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-3 col-sm-6">
              <div className="single-team-member position-relative">
                <div className="team-image">
                  <img
                    src="assets/img/affiliate/alexandre-fadel.png"
                    alt="Team Members"
                    className="img-fluid rounded-circle"
                  />
                </div>
                <div className="team-info text-white rounded-circle d-flex flex-column align-items-center justify-content-center">
                  <h5 className="mb-0">Alexandre Fadel</h5>
                  <h6>Aumente sua renda com aluguel</h6>
                  <ul className="list-inline team-social social-icon mt-4 text-white">
                    <li className="list-inline-item">
                      <a href="https://www.instagram.com/alexandrefadeladv/" target="_blank" rel="noreferrer">
                        <span className="ti-instagram"><small className="pl-1">alexandrefadeladv</small></span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-8">
              <div className="section-heading text-center mb-5">
                <span className="text-uppercase color-secondary sub-title">
                  Condição especial facilitada por
                </span>
                <h2>Alexandre Fadel</h2>
                <p className="lead">
                  Quem é Alexandre Fadel? Muito prazer, meu nome é Alexandre Fadel, sou advogado, corretor, empreendedor imobiliário, sócio fundador da Alexandre Fadel Advogados e ADR Imóveis. Há 30 anos administrando locação de imóveis, chegando a administrar mais de 1.000 contratos de locação de imóveis residenciais e comerciais, por mês. Também sou professor, desde 2009, dando aulas sobre o direito imobiliário e administração de locação de imóveis em faculdades, pós-graduações e instituições do mercado imobiliário, já tendo lecionado para mais de 5.000 alunos em todo Brasil. Atualmente ensinando também no online o meu método da Fórmula da Locação, pois durante a minha trajetória acadêmica, vi muitos corretores, na tentativa e erro, perdendo tempo e dinheiro, se frustrando porque não sabiam a melhor forma de administrar locação de imóveis. E foi por isso que decidi divulgar para o maior número de pessoas as minhas estratégias de administrar locação de imóveis, ajudando corretores a também iniciar ou crescer na administração de locação de imóveis e advocacia imobiliária com experiência, rapidez, prática, segurança jurídica e alto faturamento mensal, sem depender das vendas. Sim, porque isso é possível!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

