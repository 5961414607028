import React from "react";

export default function Team({ bgColor }) {
  return (
    <>
      <section id="team" className={`team-member-section ptb-100 ${bgColor}`}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-3 col-sm-6">
              <div className="single-team-member position-relative">
                <div className="team-image">
                  <img
                    src="assets/img/affiliate/jesse-souza.png"
                    alt="Team Members"
                    className="img-fluid rounded-circle"
                  />
                </div>
                <div className="team-info text-white rounded-circle d-flex flex-column align-items-center justify-content-center">
                  <h5 className="mb-0">Jessé Souza</h5>
                  <h6>Agência Aurora Company</h6>
                  <ul className="list-inline team-social social-icon mt-4 text-white">
                    <li className="list-inline-item">
                      <a href="https://www.instagram.com/auroranegociosdigitais/" target="_blank" rel="noreferrer">
                        <span className="ti-instagram"><small className="pl-1">auroranegociosdigitais</small></span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-8">
              <div className="section-heading text-center mb-5">
                <span className="text-uppercase color-secondary sub-title">
                  Condição especial facilitada por
                </span>
                <h2>Jessé Souza</h2>
                <p className="lead">
                  Transforme seu negócio com estratégias de gestão de tráfego e funis de vendas. Atraia e converta mais clientes hoje! Somos uma Assessoria de Marketing Digital com foco em performance, unindo gestão de tráfego pago e estratégias digitais, para ajudar sua empresa aumentar o faturamento mantendo a lucratividade.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

