import React from "react";
import { Link } from "react-router-dom";
export default function HeroSeven() {
  return (
    <>
      <section
        className="hero-section background-img ptb-100"
        style={{
          background:
            "url('assets/img/particle.svg')no-repeat center center / cover",
        }}
      >
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-7 col-lg-6">
              <div className="position-relative mt-lg-0 mt-md-0 mt-5 text-white">
                <h3 className="text-white">Você tem 21% de desconto!</h3>
                <h1 className="text-white big-text mb-2">
                  <span>MENSAL</span>
                  R$ 367,00
                </h1>
                <p className="lead">
                  Ativando sua conta através do botão abaixo
                  você garante uma economia de R$ 300,00 ao longo dos 3 primeiros meses, aproveite!
                </p>
                <Link smooth to='https://app.rentzapp.com.br/auth/novaconta/?pgclk=aB7ery' className="btn google-play-btn">
                  Resgatar meu desconto!
                </Link>
              </div>
            </div>
            <div className="col-md-5 col-lg-5">
              <div className="offer-tag-wrap position-relative z-index">
                <img
                  src="assets/img/apresentacao-rentz.png"
                  alt="app"
                  className="img-fluid"
                />
                <div className="offer-tag">
                  <div className="ribbon-2">
                    <span>Após 3 meses</span>
                  </div>
                  <div className="offer-price">
                    <span>R$</span>467<small>/Mês</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
