import React from "react";

export default function Team({ bgColor }) {
  return (
    <>
      <section id="team" className={`team-member-section ptb-100 ${bgColor}`}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-3 col-sm-6">
              <div className="single-team-member position-relative">
                <div className="team-image">
                  <img
                    src="assets/img/affiliate/nilson-reis.jpg"
                    alt="Team Members"
                    className="img-fluid rounded-circle"
                  />
                </div>
                <div className="team-info text-white rounded-circle d-flex flex-column align-items-center justify-content-center">
                  <h5 className="mb-0">Nilson Reis</h5>
                  <h6>Papo com Corretor Cast </h6>
                  <ul className="list-inline team-social social-icon mt-4 text-white">
                    <li className="list-inline-item">
                      <a href="https://www.instagram.com/papocomcorretorcast/" target="_blank" rel="noreferrer">
                        <span className="ti-instagram"><small className="pl-1">papocomcorretorcast</small></span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-8">
              <div className="section-heading text-center mb-5">
                <span className="text-uppercase color-secondary sub-title">
                  Condição especial facilitada por
                </span>
                <h2>Nilson Reis</h2>
                <p className="lead">
                  Quem é Nilson Reis? Nilson Reis, corretor com 12 anos de experiência, enfrentou um momento difícil em 2022 com uma crise de ansiedade
                  que estava caminhando para uma depressão. Foi nesse período que ele encontrou na criação de um podcast um novo
                  propósito. Através do podcast, ele compartilhou seu conhecimento e ajudou outros profissionais a enfrentar desafios no
                  setor, transformando sua dor em uma ferramenta de apoio e inspiração para o mercado imobiliário.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

